import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  private readonly _httpClient :HttpClient = inject(HttpClient);

  // get viewer data
  public getViewerProfile(viewerId:number):Observable<any>{
    return this._httpClient.get<any>(`${environment.baseUrl}viewers/${viewerId}/profile`)
  }
  // Create viewer profile
  public createViewerProfile(viewerId:number , viewerData:any){
    return this._httpClient.post(`${environment.baseUrl}viewers/${viewerId}/profile`,viewerData)
  }
  // update viewer profile
  public updateViewerProfile(viewerId:number |null , viewerData:any ){
    return this._httpClient.put(`${environment.baseUrl}viewers/${viewerId}/profile`,viewerData)
  }
}
